import { createI18n } from 'vue-i18n'

const locales = ['uz', 'ru', 'en']

const loadLocaleMessages = async () =>
    Object.fromEntries(
        await Promise.all(
            locales.map(async l => [l, (await import(`./${l}.json`)).default])
        )
    )
export default createI18n({
    locale: localStorage.getItem('locale') || 'uz',
    fallbackLocale: 'uz',
    messages: await loadLocaleMessages()
})
